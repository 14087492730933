import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";
import { translations } from "../data/translationsData";
import { LanguageContext } from "../context/LanguageContext";

const EventDetails = () => {
  const { language } = useContext(LanguageContext);
  const { eventId } = useParams();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: event.title + " | KATF",
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: "User Interaction",
      action: "Clicked A Btn Link",
      label: linkText,
    });
  };

  // Mock event details data
  const eventDetailsData = {
    "naivasha-french-day-2025": {
      title: translations[language].naivashaFrenchDayTitle,
      dateRange: translations[language].naivashaFrenchDayDateRange,
      location: translations[language].naivashaFrenchDayLocation,
      description: translations[language].naivashaFrenchDayDescription,
    },
    "butere-french-day-2025": {
      title: translations[language].butereFrenchDayTitle,
      dateRange: translations[language].butereFrenchDayDateRange,
      location: translations[language].butereFrenchDayLocation,
      description: translations[language].butereFrenchDayDescription,
    },
    "french-teachers-day-2024": {
      title: translations[language].frenchTeachersDayTitle,
      dateRange: translations[language].frenchTeachersDayDateRange,
      location: translations[language].frenchTeachersDayLocation,
      description: translations[language].frenchTeachersDayDescription,
    },
    "francophonie-month-celebration-2025": {
      title: translations[language].francophonieMonthCelebrationTitle,
      dateRange: translations[language].francophonieMonthCelebrationDateRange,
      location: translations[language].francophonieMonthCelebrationLocation,
      description: translations[language].francophonieMonthCelebrationDescription,
    },
    "mombasa-french-day-2025": {
      title: translations[language].mombasaFrenchDayTitle,
      dateRange: translations[language].mombasaFrenchDayDateRange,
      location: translations[language].mombasaFrenchDayLocation,
      description: translations[language].mombasaFrenchDayDescription,
    },
    "shimo-la-tewa-french-day-2025": {
      title: translations[language].shimoLaTewaFrenchDayTitle,
      dateRange: translations[language].shimoLaTewaFrenchDayDateRange,
      location: translations[language].shimoLaTewaFrenchDayLocation,
      description: translations[language].shimoLaTewaFrenchDayDescription,
    },
    "web-conference-2024": {
      title: translations[language].webConferenceTitle,
      dateRange: translations[language].webConferenceDateRange,
      location: null,
      description: translations[language].webConferenceDescription,
    },
    "opening-kinyanjui-resource-2024": {
      title: translations[language].openingResourceTitle,
      dateRange: translations[language].openingResourceDateRange,
      location: translations[language].openingResourceLocation,
      description: translations[language].openingResourceDescription,
    },
    "katf-elections-2024": {
      title: translations[language].electionsTitle,
      dateRange: translations[language].electionsDateRange,
      location: translations[language].electionsLocation,
      description: translations[language].electionsDescription,
    },
    "language-workshop-2024": {
      title: translations[language].languageWorkshopTitle,
      dateRange: translations[language].languageWorkshopDateRange,
      location: translations[language].languageWorkshopLocation,
      description: translations[language].languageWorkshopDescription,
    },
    "fos-workshop-2024": {
      title: translations[language].fosWorkshopTitle,
      dateRange: translations[language].fosWorkshopDateRange,
      location: translations[language].fosWorkshopLocation,
      description: translations[language].fosWorkshopDescription,
    },
    "kibabii-french-day-2025": {
      title: translations[language].kibabiiFrenchDayTitle,
      dateRange: translations[language].kibabiiFrenchDayDateRange,
      location: translations[language].kibabiiFrenchDayLocation,
      description: translations[language].kibabiiFrenchDayDescription,
    },
    "kwale-academic-day-2025": {
      title: translations[language].kwaleAcademicDayTitle,
      dateRange: translations[language].kwaleAcademicDayDateRange,
      location: translations[language].kwaleAcademicDayLocation,
      description: translations[language].kwaleAcademicDayDescription,
    },
    "kwale-cultural-film-2025": {
      title: translations[language].kwaleCulturalFilmTitle,
      dateRange: translations[language].kwaleCulturalFilmDateRange,
      location: translations[language].kwaleCulturalFilmLocation,
      description: translations[language].kwaleCulturalFilmDescription,
    },
    "reunion-trainers-training-2025": {
      title: translations[language].trainingTrainersTitle,
      dateRange: translations[language].trainingTrainersDateRange,
      location: translations[language].trainingTrainersLocation,
      description: translations[language].trainingTrainersDescription,
    },
    "cuea-francophonie-day-2025": {
      title: translations[language].cueaFrancophonieDayTitle,
      dateRange: translations[language].cueaFrancophonieDayDateRange,
      location: translations[language].cueaFrancophonieDayLocation,
      description: translations[language].cueaFrancophonieDayDescription,
    },
    "national-theatre-festival-2025": {
      title: translations[language].nationalTheatreFestivalTitle,
      dateRange: translations[language].nationalTheatreFestivalDateRange,
      location: translations[language].nationalTheatreFestivalLocation,
      description: translations[language].nationalTheatreFestivalDescription,
    },
    "cftd-teacher-training-2025": {
      title: translations[language].cftdTeacherTrainingTitle,
      dateRange: translations[language].cftdTeacherTrainingDateRange,
      location: translations[language].cftdTeacherTrainingLocation,
      description: translations[language].cftdTeacherTrainingDescription,
    },
    "katf-seminar-web-conference-2025": {
      title: translations[language].katfWebConferenceTitle,
      dateRange: translations[language].katfWebConferenceDateRange,
      location: translations[language].katfWebConferenceLocation,
      description: translations[language].katfWebConferenceDescription,
    },
    "national-music-festival-2025": {
      title: translations[language].nationalMusicFestivalTitle,
      dateRange: translations[language].nationalMusicFestivalDateRange,
      location: translations[language].nationalMusicFestivalLocation,
      description: translations[language].nationalMusicFestivalDescription,
    },
    "coast-katf-seminar-web-conference-2025": {
      title: translations[language].coastKatfWebConferenceTitle,
      dateRange: translations[language].coastKatfWebConferenceDateRange,
      location: translations[language].coastKatfWebConferenceLocation,
      description: translations[language].coastKatfWebConferenceDescription,
    },
    "teachers-training-web-conference-2025": {
      title: translations[language].teachersTrainingWebConferenceTitle,
      dateRange: translations[language].teachersTrainingWebConferenceDateRange,
      location: translations[language].teachersTrainingWebConferenceLocation,
      description: translations[language].teachersTrainingWebConferenceDescription,
    },
    "french-teachers-day-2025": {
      title: translations[language].frenchTeachersDay2025Title,
      dateRange: translations[language].frenchTeachersDay2025DateRange,
      location: translations[language].frenchTeachersDay2025Location,
      description: translations[language].frenchTeachersDay2025Description,
    },
  };

  const event = eventDetailsData[eventId];

  useEffect(() => {
    if (event) {
      document.title = `${event.title} | KATF`;
    }
  }, [event]);

  if (!event) {
    return <p>{translations[language].eventNotFound}</p>;
  }

  // Special rendering for "french-teachers-day-2024" with a link
  const renderFrenchTeachersDay = () => (
    <div className="jipf-content">
      <p>
        {event.description}
      </p>
      <Link to="https://lejourdesprofs.org/" onClick={handleLinkClick} target="_blank" rel="noopener noreferrer">
        {translations[language].frenchTeachersDayLinkText}<i className="bx bxs-hand-right"></i>
      </Link>
    </div>
  );

  // Render sections and other data dynamically for other events
  const renderDescription = (description) => {
    return (
      <div className="event-details">
        {description.title && <h2>{description.title}</h2>}

        {description.sections && description.sections.map((section, index) => (
          <div key={index}>
            <h3>{section.heading}</h3>
            <br />
            <ul>
              {section.questions.map((question, qIndex) => (
                <li key={qIndex}>{question}</li>
              ))}
            </ul>
            <br />
          </div>
        ))}

        {description.conclusion && description.conclusion.map((text, index) => (
          <p key={index}>{text}</p>
        ))}

        {description.thankYou && description.thankYou.map((text, index) => (
          <p key={index}><strong>{text}</strong></p>
        ))}

        {description.closingPrayer && description.closingPrayer.map((text, index) => (
          <p key={index}><em>{text}</em></p>
        ))}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{event.title} | KATF</title>
        <meta name="description" content={event.description.substr(0, 160)} />
        <meta property="og:title" content={event.title + " | KATF"} />
        <meta property="og:description" content={event.description.substr(0, 160)} />
        <meta property="og:image" content="/assets/katf_logo.png" />
        <meta property="og:url" content="https://katf.co.ke/events/:eventId" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={event.title + " | KATF"} />
        <meta name="twitter:description" content={event.description.substr(0, 160)}  />
        <meta name="twitter:image" content="/assets/katf_logo.png" />
        <meta name="twitter:card" content="/assets/katf_logo.png" />
      </Helmet>
      <div className="event-details">
        <h1>{event.title}</h1>
        <h3>{event.dateRange}</h3>
        {event.location && <h4>{event.location}</h4>}
        {eventId === "french-teachers-day-2024" ? (
          renderFrenchTeachersDay()
        ) : (
          typeof event.description === "string" ? (
            <p>{event.description}</p>
          ) : (
            renderDescription(event.description)
          )
        )}
      </div>
    </>
  );
};

export default EventDetails;