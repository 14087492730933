import React, { useContext, useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { translations } from "../data/translationsData";
import { LanguageContext } from "../context/LanguageContext";

const News = () => {
  const { language } = useContext(LanguageContext);
  const [selectedCategory, setSelectedCategory] = useState("Events");
  const [newsData, setNewsData] = useState([]);

  const categoryLabels = {
    Events: translations[language].events,
    "Press Releases": translations[language].newsPressRelease,
    "Random Articles": translations[language].newsRandomArticles,
  };

  const sampleNews = useMemo(() => ({
    Events: [
      {
        id: 1,
        image: "/assets/debate.webp",
        date: translations[language].articleNineDate,
        title: translations[language].articleNineTitle,
        link: "/news/inter-regional-debates-2025",
        content: translations[language].articleNineContent,
        categoryLabel: translations[language].events,
      },
      {
        id: 2,
        image: "/assets/census_art.webp",
        date: translations[language].articleEightDate,
        title: translations[language].articleEightTitle,
        link: "/news/teacher-census-2025",
        content: translations[language].articleEightContent,
        categoryLabel: translations[language].events,
      },
      {
        id: 3,
        image: "/assets/jipf_info.png",
        date: translations[language].frenchTeachersDayDateRange,
        title: translations[language].articleSevenTitle,
        link: "/news/french-teachers-day",
        content: translations[language].frenchTeachersDayDescription,
        categoryLabel: translations[language].events,
      },
      {
        id: 4,
        image: "/assets/recent_news.webp",
        date: translations[language].articleOneDate,
        title: translations[language].articleOneTitle,
        link: "/news/kicd-meeting",
        content: translations[language].articleOneContent,
        categoryLabel: translations[language].events,
      },
      {
        id: 5,
        image: "/assets/opening_resource.webp",
        date: translations[language].articleTwoDate,
        title: translations[language].articleTwoTitle,
        link: "/news/resource-centre-opening",
        content: translations[language].articleTwoContent,
        categoryLabel: translations[language].events,
      },
    ],
    "Press Releases": [
      {
        id: 6,
        image: "/assets/web_conference.png",
        date: translations[language].articleThreeDate,
        title: translations[language].articleThreeTitle,
        link: "/news/katf-web-conference",
        content: translations[language].articleThreeContent,
        categoryLabel: translations[language].newsPressRelease,
      },
      {
        id: 7,
        image: "/assets/contestants_lunch.webp",
        date: translations[language].articleFourDate,
        title: translations[language].articleFourTitle,
        link: "/news/katf-elections",
        content: translations[language].electionsDescription,
        categoryLabel: translations[language].newsPressRelease,
      }
    ],
    "Random Articles": [
      {
        id: 8,
        image: "/assets/bonjour_contact.webp",
        date: translations[language].articleFiveDate,
        title: translations[language].articleFiveTitle,
        link: "/news/french-language-popularity",
        content: translations[language].articleFiveContent,
        categoryLabel: translations[language].newsRandomArticles,
      },
      {
        id: 9,
        image: "/assets/participants.webp",
        date: translations[language].articleSixDate,
        title: translations[language].articleSixTitle,
        link: "/news/teacher-training-expansion",
        content: translations[language].articleSixContent,
        categoryLabel: translations[language].newsRandomArticles,
      }
    ]
  }), [language]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: translations[language].news + " | KATF",
    });

    // Set the initial news data to the "Events" category
    setNewsData(sampleNews[selectedCategory]);
  }, [language, selectedCategory, sampleNews]);

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: "User Interaction",
      action: "Clicked A Btn Link",
      label: linkText,
    });
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setNewsData(sampleNews[category]);
  };

  return (
    <>
      <Helmet>
        <title>{translations[language].news} | KATF</title>
        <meta name="description" content={translations[language].newsSeoDescription} />
        <meta property="og:title" content={translations[language].news + " | KATF"} />
        <meta property="og:description" content={translations[language].newsSeoDescription} />
        <meta property="og:image" content="/assets/opening_resource.webp" />
        <meta property="og:url" content="https://katf.co.ke/news" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={translations[language].news + " | KATF"} />
        <meta name="twitter:description" content={translations[language].newsSeoDescription}  />
        <meta name="twitter:image" content="/assets/katf_logo.png" />
        <meta name="twitter:card" content="/assets/katf_logo.png" />
      </Helmet>

      <div className="events-intro news-intro">
        <h1>{translations[language].news}</h1>
      </div>

      <div className="events-main news-main">
        <div className="event-buttons news-buttons">
          {Object.keys(categoryLabels).map((category) => (
            <button
              key={category}
              onClick={() => handleCategoryClick(category)}
              className={selectedCategory === category ? "active" : ""}
            >
              {categoryLabels[category]}
            </button>
          ))}
        </div>

        <div className="event-content news-content">
          {newsData.map(news => (
            <div key={news.id} className="event-card news-card">
              <div className="event-image news-image">
                <img src={news.image} alt={news.title} />
              </div>
              <div className="event-headings news-headings">
                <h5 className="news-date">{news.date}</h5>
                <h3 className="news-title">{news.title}</h3>
                <Link to={news.link} oncClick={handleLinkClick} className="news-link">{translations[language].articleLinkText}<i className="bx bxs-hand-right"></i></Link>
                <h5 id="label">{news.categoryLabel}</h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default News;