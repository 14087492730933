import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { LanguageContext } from "../context/LanguageContext";
import { translations } from "../data/translationsData";

const EventsOverview = () => {

    const { language } = useContext(LanguageContext);

    const handleLinkClick = (event) => {

        const linkText = event.target.textContent;
    
        ReactGA.event({
          category: "User Interaction",
          action: "Clicked A Btn Link",
          label: linkText,
        });
      };
    
    return(
        <div className="events-overview">
            <div className="main-event">
                {/* Image Section */}
                <div className="main-event-img">
                    <div className="events-overview-heading">
                        <h2>{translations[language].upcomingEvents}</h2>
                    </div>
                    <div className="img-container">
                        <img src="assets/main_event.png" alt={translations[language].mainEventTitle + translations[language].eventCardAltText} />
                    </div>
                </div>
                
                {/* Right Section */}
                <div className="main-event-details">
                    <h5>{translations[language].mainEventDetails}</h5>
                    <h2>{translations[language].mainEventTitle}</h2>
                    <h3>{translations[language].mainEventBenefit}</h3>
                    <div className="main-event-paragraph">
                        <p>{translations[language].mainEventParagraph}</p>
                    </div>

                    {/* Link Section */}
                    <div className="main-event-link">
                        <Link to="/events/shimo-la-tewa-french-day-2025" id="events_info" onClick={handleLinkClick}>
                            {translations[language].eventLinksText}<i className="material-symbols-sharp">trending_flat</i>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="other-events">
                <div className="other-events-flex">
                    <div className="other-event-box">
                        <div className="other-event-img">
                            <img src="/assets/upcoming_event_two.webp" alt={translations[language].eventTwoTitle + translations[language].eventCardAltText} />
                        </div>
                        <div className="other-event-description">
                            <div className="event-desc-texts">
                                <h5>{translations[language].eventTwoDetails}</h5>
                                <h3>{translations[language].eventTwoTitle}</h3>
                                <h3>{translations[language].eventTwoBenefit}</h3>
                                <p>{translations[language].eventTwoParagraph}</p>
                            </div>
                            <div className="event-explore-link">
                                <Link to="/events/kibabii-french-day-2025" onClick={handleLinkClick}>{translations[language].eventLinksText}<i className="material-symbols-sharp">trending_flat</i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="other-event-box">
                        <div className="other-event-img">
                            <img src="/assets/upcoming_event_three.webp" alt={translations[language].eventThreeTitle + translations[language].eventCardAltText}/>
                        </div>
                        <div className="other-event-description">
                            <div className="event-desc-texts">
                                <h5>{translations[language].eventThreeDetails}</h5>
                                <h3>{translations[language].eventThreeTitle}</h3>
                                <h3>{translations[language].eventThreeBenefit}</h3>
                                <p>{translations[language].eventThreeParagraph}</p>
                            </div>
                            <div className="event-explore-link">
                                <Link to="/events/reunion-trainers-training-2025" onClick={handleLinkClick}>{translations[language].eventLinksText}<i className="material-symbols-sharp">trending_flat</i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="other-event-box">
                        <div className="other-event-img">
                            <img src="/assets/upcoming_event_four.webp" alt={translations[language].eventFourTitle + translations[language].eventCardAltText}/>
                        </div>
                        <div className="other-event-description">
                            <div className="event-desc-texts">
                                <h5>{translations[language].eventFourDetails}</h5>
                                <h3>{translations[language].eventFourTitle}</h3>
                                <h3>{translations[language].eventFourBenefit}</h3>
                                <p>{translations[language].eventFourParagraph}</p>
                            </div>
                            <div className="event-explore-link">
                                <Link to="/events/national-theatre-festival-2025" onClick={handleLinkClick}>{translations[language].eventLinksText}<i className="material-symbols-sharp">trending_flat</i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventsOverview;