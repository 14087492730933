import React, { useContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { translations } from "../../data/translationsData";
import { LanguageContext } from "../../context/LanguageContext";

const UpcomingEvents = ({ category }) => {

  const { language } = useContext(LanguageContext);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: `${category ? category : translations[language].upcomingEvents} | KATF`,
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: "User Interaction",
      action: "Clicked A Btn Link",
      label: linkText,
    });
  };

  // Mock data for upcoming events
  const upcomingEvents = [
    {
      image: "/assets/shimo_la_tewa_french_day.png",
      dateRange: translations[language].shimoLaTewaFrenchDayDateRange,
      title: translations[language].shimoLaTewaFrenchDayTitle,
      category: translations[language].frenchDaysEventCategory,
      link: "/events/shimo-la-tewa-french-day-2025",
      categoryLabel: translations[language].frenchDaysEventCategory,
    },
    {
      image: "/assets/kibabii_french_day.png",
      dateRange: translations[language].kibabiiFrenchDayDateRange,
      title: translations[language].kibabiiFrenchDayTitle,
      category: translations[language].frenchDaysEventCategory,
      link: "/events/kibabii-french-day-2025",
      categoryLabel: translations[language].frenchDaysEventCategory,
    },
    {
      image: "/assets/training_trainers.png",
      dateRange: translations[language].trainingTrainersDateRange,
      title: translations[language].trainingTrainersTitle,
      category: translations[language].workshopEventCategory,
      link: "/events/reunion-trainers-training-2025",
      categoryLabel: translations[language].workshopEventCategory,
    },
    {
      image: "/assets/national_theatre_festival.png",
      dateRange: translations[language].nationalTheatreFestivalDateRange,
      title: translations[language].nationalTheatreFestivalTitle,
      category: translations[language].otherEventCategory,
      link: "/events/national-theatre-festival-2025",
      categoryLabel: translations[language].otherEventCategory,
    },
    {
      image: "/assets/katf_seminar_webconference.png",
      dateRange: translations[language].katfWebConferenceDateRange,
      title: translations[language].katfWebConferenceTitle,
      category: translations[language].conferenceEventCategory,
      link: "/events/katf-seminar-web-conference-2025",
      categoryLabel: translations[language].conferenceEventCategory,
    },
    {
      image: "/assets/cftd_teacher_training.png",
      dateRange: translations[language].cftdTeacherTrainingDateRange,
      title: translations[language].cftdTeacherTrainingTitle,
      category: translations[language].workshopEventCategory,
      link: "/events/cftd-teacher-training-2025",
      categoryLabel: translations[language].workshopEventCategory,
    },
    {
      image: "/assets/kwale_academic_day.png",
      dateRange: translations[language].kwaleAcademicDayDateRange,
      title: translations[language].kwaleAcademicDayTitle,
      category: translations[language].otherEventCategory,
      link: "/events/kwale-academic-day-2025",
      categoryLabel: translations[language].otherEventCategory,
    },
    {
      image: "/assets/kwale_cultural_film.png",
      dateRange: translations[language].kwaleCulturalFilmDateRange,
      title: translations[language].kwaleCulturalFilmTitle,
      category: translations[language].otherEventCategory,
      link: "/events/kwale-cultural-film-2025",
      categoryLabel: translations[language].otherEventCategory,
    },
    {
      image: "/assets/national_music_festival.png",
      dateRange: translations[language].nationalMusicFestivalDateRange,
      title: translations[language].nationalMusicFestivalTitle,
      category: translations[language].otherEventCategory,
      link: "/events/national-music-festival-2025",
      categoryLabel: translations[language].otherEventCategory,
    },
    {
      image: "/assets/coast_katf_seminar_webconference.png",
      dateRange: translations[language].coastKatfWebConferenceDateRange,
      title: translations[language].coastKatfWebConferenceTitle,
      category: translations[language].conferenceEventCategory,
      link: "/events/coast-katf-seminar-web-conference-2025",
      categoryLabel: translations[language].conferenceEventCategory,
    },
    {
      image: "/assets/teachers_training_webconference.png",
      dateRange: translations[language].teachersTrainingWebConferenceDateRange,
      title: translations[language].teachersTrainingWebConferenceTitle,
      category: translations[language].conferenceEventCategory,
      link: "/events/teachers-training-web-conference-2025",
      categoryLabel: translations[language].conferenceEventCategory,
    },
    {
      image: "/assets/international_french_teachers_day_2025.png",
      dateRange: translations[language].frenchTeachersDay2025DateRange,
      title: translations[language].frenchTeachersDay2025Title,
      category: translations[language].frenchDaysEventCategory,
      link: "/events/french-teachers-day-2025",
      categoryLabel: translations[language].frenchDaysEventCategory,
    },
  ];

  const filteredEvents = category ? upcomingEvents.filter(event => event.category === category) : upcomingEvents;

  return (
    <>
      <div className="list-heading">
        <h2>{translations[language].upcomingEvents}</h2>
      </div>
      <div className="upcoming-events-list">
        {filteredEvents.length > 0 ? (
          filteredEvents.map(event => (
            <div key={event.title} className="event-card">
              <div className="event-image">
                <img src={event.image} alt={event.title} />
              </div>
              <div className="event-headings">
                <h5 className="red-heading">{event.dateRange}</h5>
                <h3>{event.title}</h3>
                <Link to={event.link} onClick={handleLinkClick}>{translations[language].eventDetailsLinkText}<i className="bx bxs-hand-right"></i></Link>
                <h5 id="label">{event.categoryLabel}</h5>
              </div>
            </div>
          ))
        ) : (
          <p>No {category ? category : translations[language].upcomingEvents} events available.</p>
        )}
      </div>
    </>
  );
}

export default UpcomingEvents;